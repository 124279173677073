<div  class="column">
<button type="button" class="fa btn-message" >
    <i class="fa-commenting btn-message" data-toggle="modal" data-target="#contactModal" data-whatever=""></i>
</button>
</div>
<!-- <div *ngIf="modalClose==true"></div> -->
<div class="modal fade"  id="contactModal" tabindex="-1" role="dialog" aria-labelledby="contactModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="contactModalLabel">
          Contact us:     
          <p style="color: #e0ca7c;">contact@dablde.com</p>        
        </h5>
      </div>
    
    <div class="modal-body">
      <form class="contact-form" (submit)="sendEmail($event)">
        <label class="col-form-label">Name</label>
        <input type="text" class="form-control" name="user_name">
        <label class="col-form-label">Email</label>
        <input type="email" class="form-control" name="user_email">
        <label class="col-form-label">Message</label>
        <textarea name="message" class="form-control"></textarea>
        <br/>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" type="submit"  value="Send"> Send message</button>
        </div>
    </form>
  </div>
    </div>
  </div>
</div>
