<div id="main-wrapper" [ngClass]="{'defaultdark': color == 'defaultdark'}">
    <!-- Header -->
    <app-topbar></app-topbar>
    <!-- Header -->

    <!-- sidebar -->
    <app-sidebar></app-sidebar>
    <!-- sidebar -->

    <!-- Core -->
    <app-portfolio></app-portfolio>
    <!-- Core -->

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- Footer -->
</div>