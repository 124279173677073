    <!-- Header SPA -->

<div class="top" *ngIf="isSPA()==true">
    <div class="left-logo"><img (click)="reloadPage()" class="logo-img" style="" src="assets/images/svg/logo.svg" alt=""> </div>
    <div style="text-align: center">
        <ul class="skill-set" style="display: inline-block">
            <li style="cursor: pointer;" 
                [ngClass]="{'activated': post==='information'}" (click)="scrollToSection('information')">ABOUT US</li>
            <li style="cursor: pointer;" 
                [ngClass]="{'activated': post==='biography'}" (click)="scrollToSection('biography')">BIO</li>
            <li style="cursor: pointer;" 
                [ngClass]="{'activated': post==='discography'}" (click)="scrollToSection('discography')">DISCO</li>
          <!--  <li style="cursor: pointer;" 
                [ngClass]="{'activated': post==='references'}" (click)="scrollToSection('references')">Ref</li> -->
        </ul>
    </div>
</div> 
    <!-- End Header SPA -->


    <!-- Header multi page Portfolio -->
    <div class="top" *ngIf="isSPA()==false">
        <div class="left-logo"><img [routerLink]="['/']" class="logo-img" style="" src="assets/images/svg/logo.svg" alt=""></div>
        <div style="text-align: center">
            <ul class="skill-set" style="display: inline-block">
                <li style="cursor: pointer;" (click)="changeToMenu('information')"
                    [ngClass]="{'activated': post==='information'}" [routerLink]="['/information']">ABOUT US</li>
                <li style="cursor: pointer;" (click)="changeToMenu('biography')"
                    [ngClass]="{'activated': post==='biography'}" [routerLink]="['/biography']">BIO</li>
                <li style="cursor: pointer;" (click)="changeToMenu('discography')"
                    [ngClass]="{'activated': post==='discography'}" [routerLink]="['/discography']">DISCO</li>
          <!--      <li style="cursor: pointer;" (click)="changeToMenu('references')"
                    [ngClass]="{'activated': post==='references'}" [routerLink]="['/references']">Ref</li> -->
            </ul>
        </div>
    </div>
    <!-- End Header multi page Portfolio -->
