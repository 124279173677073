<header class="topbar">
    <nav class="navbar navbar-default navbar-fixed-top">
        <div class="navbar-header">
            <a class="navbar-brand">
                <b>
                    <img src="assets/images/logo-light-icon.png" alt="homepage" class="logo" />
                </b>
                
            </a>
        </div>
        <div class="navbar-collapse">
            <ul class="navbar-nav mr-auto mt-md-0 ">
                <li class="nav-item m-l-10"> <a class="nav-link sidebartoggler hidden-lg-up text-muted waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
            </ul>
            
        </div>
    </nav>
</header>