
<div id="content">

    
    <div class="middle"></div>
    <div class="middle-text col-md-8 offset-md-2 col-sm-12">
        <div class="inner content-card" id="inner-content">
            <router-outlet></router-outlet>
        </div>
            <!-- Signature -->
            <section id="handmade">
                <div class="handmade-box">
                    <p><em>
                            <a href="https://www.instagram.com/yadefame/" target="_blank">Defame</a>
                             & 
                            <a href="https://www.instagram.com/wassimwz/" target="_blank">Dabl U</a>
                        </em>
                    </p>
                </div>
            </section>
    <!-- Signature -->
    </div>
</div>
