import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { FullComponent } from './layouts/full/full.component';

export const Approutes: Routes = [
{
    path: '',
    component: FullComponent,
    children: [
        { path: '', redirectTo: '/', pathMatch: 'full' },
        { path: '', loadChildren: () => import('./portfolio/portfolio.module').then(m => m.PortfolioModule) },
    ]
},
{
    path: 'biography',
    redirectTo: '/biography'
},
{
    path: 'discography',
    redirectTo: '/discography'
},
{
    path: 'information',
    redirectTo: '/information'
},
{
    path: 'references',
    redirectTo: '/references'
},
{
    path: 'contact',
    redirectTo: '/contact'
},
{
    path: '**',
    redirectTo: '/'
}];


