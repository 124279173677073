import { Component, AfterViewInit, OnInit, OnChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Component({
	selector: 'app-portfolio',
	templateUrl: './portfolio.component.html',
	styleUrls: ['./portfolio.component.css'],
	providers: []
})
export class PortfolioComponent implements OnInit,OnChanges {
	public post:any;
	public data:any;
	public db:any;
	public query:any;
	constructor(public router: Router,private http: HttpClient) {
		this.post = router.url.replace('/','') ;
		this.query = {
			"query": "query { biography {title period description} discography {name url links {platform icon url}} references {platform icon url} information {title } }",
			"variables": {}
		}
	}

  httpOptions = {
    headers: new HttpHeaders({
	//	'Access-Control-Request-Headers': '*',
      'Content-Type': 'application/json',
	  'apiKey': 'HIpXviHY6gPOfbQYbeMxXA4IXWruG4BNOlJ8qiM805eGhy8RC1uAjvxaT7vf9TbU'
    })
  };
  

  async postBlog(query: any) {
    let url = "https://realm.mongodb.com/api/client/v2.0/app/dabl_de-fwbuu/graphql";
    this.db =  await this.http.post(url, query, this.httpOptions).toPromise();
	console.log(this.db)
  
}

	ngOnInit() {
		//this.postBlog(this.query);

	}

	ngOnChanges() {
	}

	changeToMenu(menu:string) {
		this.post = menu;
	}
	scrollToTop() {
		document.getElementById('content').scrollIntoView(true);
	}

	sendMail() {
		window.open('mailto:contact@dablde.com');
	}
}
