import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
	public post:any;
	public checkSPA:any;

  constructor(public route: ActivatedRoute,public router: Router) { 
    this.post = router.url.replace('/','') ;
    this.checkSPA = false;
  }

  ngOnInit(): void {
  }

  reloadPage() {
    window.location.reload();
 }
 
  isSPA() {
    if(this.router.url=='/'){
      this.checkSPA = true;
    } else {
      this.checkSPA = false;
    }
    return this.checkSPA;
  }

  changeToMenu(menu:string) {
		this.post = menu;
    console.log(this.router.url);

	}
  

  scrollToSection(section:string) {
    this.post = section;
		document.getElementById(section).scrollIntoView({
      block: 'start',
      behavior: 'smooth',
      inline: 'nearest'
  });
	}

}
