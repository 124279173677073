import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-contact-box',
  templateUrl: './contact-box.component.html',
  styleUrls: ['./contact-box.component.css']
})
export class ContactBoxComponent implements OnInit {

  public recipentName: string;
  public recipentMail: string;
  public recipentMessage: string;

  public messageForm = new FormGroup({
    recipentName: new FormControl(""),
    recipentMail: new FormControl(""),
    recipentMessage: new FormControl("")
    })

	public mail:any;

  constructor(private http: HttpClient) { 
  }

  ngOnInit(): void {
  }

  httpOptions = {
    headers: new HttpHeaders({
	//	'Access-Control-Request-Headers': '*',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': '*',
  'Access-Control-Allow-Methods': 'PUT, GET, HEAD, POST, DELETE, OPTIONS',
  'Content-Type': 'application/json',
  'Accept'  : 'application/json, text/plain, */*',
  'Connection': 'keep-alive'
    })
  };

  reloadPage() {
    window.location.reload();
 }

  public sendEmail(e: Event) {
    e.preventDefault();
    emailjs.sendForm('service_zxsa1rd', 'template_ebgl2vg', e.target as HTMLFormElement, 'wHYmg6e1dPgqPNYr4')
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
        this.reloadPage();
      }, (error) => {
        this.reloadPage();
        console.log(error.text);
      });
  }

}
