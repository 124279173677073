import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  public references:any;
  constructor() { }
  
  fetch(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', `assets/data/references.json`);

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
  }

  ngOnInit() {
    this.fetch((data) => {
      this.references = data.references;
    });
    document.getElementById('inner-content').scrollIntoView();

  }
}
