import { Component, OnInit, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'; 
import { UrlbypassPipe } from 'src/app/pipes/url-bypass.pipe';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full.component.html',
    styleUrls: ['./full.component.scss'],
    providers: [UrlbypassPipe]
})
export class FullComponent implements OnInit,OnChanges {

    color = 'defaultdark';
    showSettings = false;
    showMinisidebar = false;
    showDarktheme = false;
	public data:any;
	public db:any;
	public query:any;


    public config: PerfectScrollbarConfigInterface = {};

    constructor(public router: Router,private http: HttpClient,public urlbypassPipe: UrlbypassPipe) {
 
		this.query = {
			"query": "query { biography {title period description} discography {name url links {platform icon url}} references {platform icon url} information {title } }",
			"variables": {}
		}
	}
     httpOptions = {
        headers: new HttpHeaders({
        //	'Access-Control-Request-Headers': '*',
          'Content-Type': 'application/json',
          'apiKey': 'HIpXviHY6gPOfbQYbeMxXA4IXWruG4BNOlJ8qiM805eGhy8RC1uAjvxaT7vf9TbU'
        })
      };
      
    
      async postBlog(query: any) {
        let url = "https://realm.mongodb.com/api/client/v2.0/app/dabl_de-fwbuu/graphql";
        this.db =  await this.http.post(url, query, this.httpOptions).toPromise();
        console.log(this.db)
      
    }
    
        ngOnInit() {
           // this.postBlog(this.query);
    
        }


    ngOnChanges() {
        // this.page = this.route.snapshot._routerState.url;
    }

 
	scrollToTop() {
		document.getElementById('content').scrollIntoView(true);
	}

    /*  iFrame Spotify  */
    public embedURI: any;
    public selected: any;
    public playlistSelected: boolean;

    onPlaylistSelect(playlist) {
        console.log(playlist);
        this.selected = playlist;
        this.embedURI = "https://open.spotify.com/embed?uri=" + this.selected.uri;
        console.log(this.embedURI);
        this.playlistSelected = true;
      }
}
